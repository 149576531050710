export function ajaxForm() {

  function handleSubmitForm(e) {
    e.preventDefault();
    let $target = $(e.target)
    $target.find('.alert').remove();

    $.ajax({
      url: $target.attr('action'),
      method: $target.attr('method'),
      data: $target.serialize(),
    })
      .done(function () {
        location.reload();
      })
      .fail(function (jqXHR, textStatus) {
        console.log(jqXHR, textStatus)
        if (jqXHR.status === 419) {
          $target.append('<div class="alert alert-danger mt-2"><p class="m-0">Your sessions timed out. <a class="btn btn-outline-light text-decoration-none" href="#" onclick="window.location.reload()">reload the page</a></p></div>');
        } else {
          $target.append('<div class="alert alert-danger mt-2"><p class="m-0">' + jqXHR.responseJSON.message + '</p></div>');
        }
      })
      .always(function () {
      });
  }

  $('body').on('submit', 'form', handleSubmitForm)
}
